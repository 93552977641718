import React, { FunctionComponent, useEffect, useState } from 'react';
import { WindowLocation } from "../types/PageProps";
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import GalleryComp from '../components/imageGallery';

const ImageGallery: FunctionComponent<PageProps> = ({ location }) => {
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);

  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
  }, []);
  
  return (
    <Layout location={location}>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Dolores Hair Designers</title>
        <link rel="canonical" href="http://doloreshairdesigners.com/about" />
      </Helmet>
      <GalleryComp />
    </Layout>
  );
};

export default ImageGallery;