import React from 'react';
import Gallery from 'react-grid-gallery';
import Typography from '@material-ui/core/Typography';
import imageOne from '../images/7dbf82f9-2aab-445a-985d-89425616e0c8.jpeg';
import imageTwo from '../images/f3c18ded-0471-4643-9174-ee30d56acf55.jpeg';
import imageThree from '../images/0a917c7a-757d-4472-8da4-27e3e77ab459.jpeg';
import imageFour from '../images/33328508-f087-48e8-8f4d-b93f678937d0.jpeg';
import imageFive from '../images/68c75a22-6435-4e26-8ef9-fe635c944f3f.jpeg';
import imageSix from '../images/68c75a22-6435-4e26-8ef9-fe635c944f3f.jpeg';
import imageSeven from '../images/f6e068ed-d406-4621-83b0-bdb48d51b0d1.jpeg';
import imageEight from '../images/b3b591c7-2da7-42c4-b210-fcc56ea9618c.jpeg';
import imageNine from '../images/754b0c5f-9d57-4ca8-8c55-1b2fb9111057.jpeg';
import imageTen from '../images/e69c13f6-d0aa-4560-afc6-ab265bc7937f.jpeg';
import imageEleven from '../images/100cd1ea-3057-4d94-9674-bf854e2c49cd.jpeg';
import imageTwelve from '../images/c6484866-db3d-42a2-b0b7-9734896f2e73.jpeg';
import imageThirteen from '../images/fe272868-e45d-46aa-bf91-c9c42bd5f509.jpeg';
import imageFourteen from '../images/ba138f18-4c91-4201-b277-5d68931d6f9a.jpeg';
import imageFifteen from '../images/c1703df3-c0e2-49fb-83b3-3e99e27b15ef.jpeg';
import imageSixteen from '../images/image0.jpeg';
import imageSeventeen from '../images/image1.jpeg';
import imageEighteen from '../images/20200713_161817.jpg';
import imageNineteen from '../images/screenshot.png';

const GalleryComp = () => {

  const IMAGES = [
    {
      src: imageOne,
      thumbnail: imageOne,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageTwo,
      thumbnail: imageTwo,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageThree,
      thumbnail: imageThree,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageFour,
      thumbnail: imageFour,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Almost"
    },
    {
      src: imageFive,
      thumbnail: imageFive,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageSix,
      thumbnail: imageSix,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageSeven,
      thumbnail: imageSeven,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageEight,
      thumbnail: imageEight,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageNine,
      thumbnail: imageNine,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageTen,
      thumbnail: imageTen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageEleven,
      thumbnail: imageEleven,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageTwelve,
      thumbnail: imageTwelve,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageThirteen,
      thumbnail: imageThirteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageFourteen,
      thumbnail: imageFourteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "Before"
    },
    {
      src: imageFifteen,
      thumbnail: imageFifteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: "After"
    },
    {
      src: imageSixteen,
      thumbnail: imageSixteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
    },
    {
      src: imageSeventeen,
      thumbnail: imageSeventeen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
    },
    {
      src: imageEighteen,
      thumbnail: imageEighteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
      caption: 'Photograph great looks and upload them straight to instagram with our Ring Light!'
    },
    {
      src: imageNineteen,
      thumbnail: imageNineteen,
      thumbnailWidth: 320,
      thumbnailHeight: 400,
    }
  ];

  return (
    <div style={{ marginTop: 50 }}>
      <Typography align='center' variant="h2" color='secondary' style={{fontFamily: 'Raleway', paddingBottom: 25}}>
        Image Gallery
      </Typography>
      <Gallery images={IMAGES} rowHeight={400} margin={5} />
    </div>
  );
};

export default GalleryComp;